$(function () {	
	var marketingPagesize = $('#marketing-pagesize-count').val(),
		marketingDataTotalCount = $('#marketing-total-results-count').val(),
		IsUserAuthenticated = $("#IsUserAuthenticated").val(),
		currentPage = 1;
				
	var fetchMarketingData = function(pageNo, pageSize){		
		$("#marketingPaginationBackend").hide();
		$("#marketingDataFromBackend").hide();
        $.get("/api/marketingpage/getmarketingpages", {
			"pId": "bfc4fa2c-23f4-45d6-83f6-917b93d2360f",			
            "page": pageNo,
            "perPage": pageSize,
        }, function (respone) {
            var marketingData = "";
			respone.results.forEach(item => {
				marketingData += `
					<div class="tile-list">
						<label class="action-flag pop-out__trigger ${IsUserAuthenticated === 'true' ? "js-bookmark-article" : "js-pop-out-trigger"}"
							   data-pop-out-type="sign-in"
							   data-pop-out-align="right"
							   data-bookmark-id="${item.itemId}"
							   ${item.salesforceId ? `data-salesforce-id="${item.salesforceId}"` : `data-salesforce-id`}
							   ${item.isBookmarked ? "data-is-bookmarked=true" : ""}
							   data-info='{
											"event_name": "${item.isBookmarked ? "unbookmark" : "bookmark"}",
											"toolbar_tool": "bookmark",
											"bookmark_title": "${item.title}"}'>

							<svg class="action-flag__icon action-flag__icon--bookmark article-bookmark article-bookmark__bookmarked ${item.isBookmarked ? "is-visible" : null}">
								<use xlink:href="/dist/img/svg-sprite.svg#bookmark_filled"></use>
							</svg>

							<svg class="action-flag__icon action-flag__icon--bookmark article-bookmark ${item.isBookmarked ? null : "is-visible"}">
								<use xlink:href="/dist/img/svg-sprite.svg#bookmark_border"></use>
							</svg>
						</label>
						
						<h3 class="tile-list-title"><a href="${item.url}">${item.title}</a></h3>
						<div class="tile-list-metas">
							<span>${item.searchDate.toString()}</span>
							${item.authorName ? `<span><a href="${item.authorUrl}">${item.authorName}</a></span>` : ""}
						</div>
						<p>${item.summary}</p>
						<a href="${item.url}" target="_blank" class="tile-list-link readmore">Read More</a>
					</div>`;
			});
			
            $("#marketingDataFromFrontend").html(marketingData);
			$("#marketingPaginationFrontend").pagination({
				dataTotalLength: respone.totalResults,
				currentPageItem: pageNo,
				dataPerPage: pageSize
			});
			// initiate signin popup and bookmark function
			window.indexPopOuts();
			window.indexBookmarks();
        });
    }
	
	var parseHashParms = function(str) {
		str = str ? str.split("?")[1] : "";
		var pieces = str ? str.split("&") : "",
			data = {},
			i,
			parts;
		// process each query pair
		if(pieces && pieces.length > 0){
			for (i = 0; i < pieces.length; i++) {
				parts = pieces[i].split("=");
				if (parts.length < 2) {
					parts.push("");
				}
				data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
			}
		}
		return data;
	}
	
	var updateHashParam = function(key, value){
		var hashValue = window.location.search,
			paramObj = {};
			
		if (hashValue == "" && key != "page") {
			hashValue += key + "=" + value;
		} else {
			paramObj = parseHashParms(hashValue);
			if (value != "") {
				paramObj[key] = value;
				if(key == "page" && value < 2){
					delete paramObj[key];
				}
			} else {
				delete paramObj[key];
			}
			hashValue = Object.keys(paramObj).map(function (key) {
				return key + '=' + paramObj[key];
			}).join('&');
		}
		if (history.pushState) {
			if(hashValue != ""){
				var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?'+hashValue;
			}else{
				var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
			}
			window.history.pushState({path:newurl},'',newurl);
		}
	}
		
	var hashParam = window.location.search,
		hashParamObj = "";
	if(hashParam && marketingPagesize){
		hashParamObj = parseHashParms(hashParam);
	}
	
	if(marketingPagesize) {
		if(hashParamObj.page){
			fetchMarketingData(hashParamObj.page, marketingPagesize);
			$('link[rel="canonical"]').attr('href', window.location);
		} else {
			fetchMarketingData(1, marketingPagesize);
		}
	}
	
	$("#marketingPaginationFrontend").on('click', "li", function(e){
		e.preventDefault();
		var pageIndex = $(this).find("a").attr("data-index");
		currentPage = pageIndex;
		updateHashParam("page", pageIndex);
		fetchMarketingData(pageIndex, marketingPagesize);
		$('link[rel="canonical"]').attr('href', window.location);
	});
	var pressFeedUrl = $("#pressFeedUrl").val();
	$("#pressFeedData").click(function(){
		var url = pressFeedUrl + "?page=" + currentPage + "&perPage=" + marketingPagesize;
		window.open(url, '_blank');
	});
	
    $.fn.pagination = function (options) {		
		var _this = this,
			defaults = {
				dataPerPage: 10,
				populatePageCount: 5,
				startPageFrom: 1,
			},
			settings = $.extend({}, defaults, options),
			firstPageIndex = 1,
			initPageIndex = settings.startPageFrom,
			lastPageIndex = settings.populatePageCount,
			pageIncrement,
			activePageIndex = 1,
			totalPageCount = "",
			currentPageItem = settings.currentPageItem;
			
			
		if(this.length > 1){
			this.each(function(){ $(this).pagination(options) });
			return this;
		}
	
		this.populateData = function(){
			totalPageCount = Math.ceil(settings.dataTotalLength / settings.dataPerPage);
			var paginationItem = "";
				
			if(initPageIndex != firstPageIndex){
				paginationItem += `<li>
									<a href="#?page=${firstPageIndex}" data-index="${firstPageIndex}" title="Page ${firstPageIndex}">First</a>
								   </li>
								   <li>...</li>`;
			}
			
			for (var i = initPageIndex; i <= totalPageCount; i++) {
				if(i <= lastPageIndex){
					if(activePageIndex && activePageIndex == i){
						paginationItem += `<li class="active">
											<a href="#?page=${i}" data-index="${i}" title="Page ${i}">${i}</a>
										  </li>`;
					}else{					
						paginationItem += `<li>
											<a href="#?page=${i}" data-index="${i}" title="Page ${i}">${i}</a>
										  </li>`;
					}
				}
			}
			
			if(totalPageCount > lastPageIndex){
				paginationItem += `<li>...</li>`
				
				if(totalPageCount == activePageIndex){
					paginationItem += `<li class"active">
										<a href="#?page=${totalPageCount}" data-index="${totalPageCount}" title="Page ${totalPageCount}">Last</a>
									  </li>`;
				}else {
					paginationItem += `<li>
										<a href="#?page=${totalPageCount}" data-index="${totalPageCount}" title="Page ${totalPageCount}">Last</a>
									  </li>`;
				}
			}
			
			if (totalPageCount <= firstPageIndex) {
				paginationItem = "";
			}			
			this.html(paginationItem);	
		}
		
		this.initialize = function(){
			_this.populateData();
				
			if(currentPageItem){
				activePageIndex = currentPageItem;
			}

			if (currentPageItem && currentPageItem > 2 && currentPageItem <= totalPageCount - 2) {
				initPageIndex = Number(currentPageItem) - 2;
				lastPageIndex = Number(currentPageItem) + 2;
			}
			if (currentPageItem && currentPageItem <= 2) {
				initPageIndex = settings.startPageFrom,
				lastPageIndex = settings.populatePageCount
			}
			if(totalPageCount > 5){
				if (currentPageItem && currentPageItem >= totalPageCount - 2) {
					initPageIndex = totalPageCount - 4;
					lastPageIndex = totalPageCount;
				}
			}
			_this.populateData();
		};
		this.initialize();		
			
		$(_this).on('click', "li", function(e){
			e.preventDefault();
			//e.stopPropagation();
			currentPageItem = $(this).find("a").attr("data-index");
			_this.initialize();
		});
	};
});