$(function(){
	var insightsPageSize = $("#insight-filter-page-size").val(),
	    analystData,
	    SegmentFilterVal = "",
	    insightServiceAreaVal = "",
	    insightsDataTotalCount = $("#insight-data-total-count").val();
		
	var parseHashParms = function(str) {
		str = str ? str.split("?")[1] : "";
		var pieces = str ? str.split("&") : "",
			data = {},
			i,
			parts;
		// process each query pair
		if(pieces && pieces.length > 0){
			for (i = 0; i < pieces.length; i++) {
				parts = pieces[i].split("=");
				if (parts.length < 2) {
					parts.push("");
				}
				data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
			}
		}
		return data;
	}
	
	var hashParam = window.location.search,
		hashParamObj = "";
	if(hashParam && insightsPageSize){
		hashParamObj = parseHashParms(hashParam);
		if(hashParamObj && hashParamObj.SegmentFilter){
			SegmentFilterVal = hashParamObj.SegmentFilter;
		}
		if(hashParamObj && hashParamObj.ServiceAreaFilter){
			insightServiceAreaVal = hashParamObj.ServiceAreaFilter;
		}
	}	
	
	var fetchInsightsData = function(pageNo, pageSize){
		$("#insightsPaginationBackend").hide();
		$("#insightsDataFromBackend").hide();
        $.post("/api/insightsfilter/getinsightitems", {
            "PageNo": pageNo,
            "PageSize": pageSize,
			"SegmentFilter": SegmentFilterVal,
			"ServiceAreaFilter": insightServiceAreaVal
        }, function (result) {
            analystData = "";
			result.respone.insightItems.forEach(item => {
				analystData += `
					<div class="card-list">
						<img 
							src="${item.image && item.image[0] ? item.image[0] : '/-/media/tech/omdia/herobannerwithtile/herobannerwithtileplaceholder.jpeg'}" 
							alt="${item.image && item.image[1] ? item.image[1] : 'placeholder-img'}">
						<div class="card-list-content">
							<h3 class="card-list-title"><a href="${item.url}">${item.title}</a></h3>
							<p>${item.body}</p>
							<a href="${item.url}" class="cta cta-arrow-right">Learn More</a>
						</div>
					</div>`;
			});
			hashParam = window.location.search;
			if (hashParam) {
				hashParamObj = parseHashParms(hashParam);
			}
			insightsDataTotalCount = result.respone.count;	
			if (hashParam && hashParamObj.page) {
				initPagintation(hashParamObj.page);
			} else {
				initPagintation(firstPage);
			}
            $("#insightsDataFromFrontend").html(analystData);
        });
    }
    
	var totalPageCount,
		paginationHtml,
	    paginationItem,
	    pageIncrement,
	    initPage = 1,
	    firstPage = 1,
	    lastPage = 5,
	    activePage = 1;

	var initPagintation = function(currentItem) {
		totalPageCount = Math.ceil(insightsDataTotalCount / insightsPageSize);
		if (currentItem && currentItem > 2 && currentItem <= totalPageCount - 2) {
			firstPage = Number(currentItem) - 2;
			lastPage = Number(currentItem) + 2;
		}
		if (currentItem && currentItem <= 2) {
			firstPage = 1;
			lastPage = 5;
		}
		if(totalPageCount > 5){
			if (currentItem && currentItem >= totalPageCount - 2) {
				firstPage = totalPageCount - 4;
				lastPage = totalPageCount;
			}
		}
		paginationHtml = "";
		if(firstPage != 1){
			paginationHtml += `
				<li>
					<a href="#?page=${initPage}" data-index="${initPage}" title="Page ${initPage}">First</a>
				</li>
				<li>...</li>`;
		}
		for(var i = firstPage; i <= totalPageCount; i++){
			paginationItem = `<li>
								<a href="#?page=${i}" data-index="${i}" title="Page ${i}">${i}</a>
							</li>`;
			if(currentItem && currentItem == i){
				paginationItem = `<li class="active">
									<a href="#?page=${i}" data-index="${i}" title="Page ${i}">${i}</a>
								</li>`;
			}
			if(i <= lastPage){
				paginationHtml += paginationItem;
			}
		}
		if(totalPageCount > lastPage){
			paginationHtml += `
				<li>...</li>
				<li>
					<a href="#?page=${totalPageCount}" data-index="${totalPageCount}" title="Page ${totalPageCount}">Last</a>
				</li>`;
		}
		if (totalPageCount < 2) {
			paginationHtml = "";
		}
		$("#insightsPagination").html(paginationHtml);
	}
	
	var updateHashParam = function(key, value){
		var hashValue = window.location.search,
			paramObj = {};
			
		if (hashValue == "" && key != "page") {
			hashValue += key + "=" + value;
		} else {
			paramObj = parseHashParms(hashValue);
			if (value != "") {
				paramObj[key] = value;
				if(key == "page" && value < 2){
					delete paramObj[key];
				}
			} else {
				delete paramObj[key];
			}
			hashValue = Object.keys(paramObj).map(function (key) {
				return key + '=' + paramObj[key];
			}).join('&');
		}
		if (history.pushState) {
			if(hashValue != ""){
				var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?'+hashValue;
			}else{
				var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
			}
			window.history.pushState({path:newurl},'',newurl);
		}
	}
		
	$(document).on('click', "#insightsPagination li", function(e){
		e.preventDefault();
		//e.stopPropagation();
		var pageIndex = $(this).find("a").attr("data-index");
		updateHashParam("page", pageIndex);
		initPagintation(pageIndex);
		fetchInsightsData(pageIndex, insightsPageSize);
		activePage = pageIndex;
	});
		
	$("#insightSegment").on('click', '.custom-select-list li', function () {
		SegmentFilterVal = $(this).attr('data-list-value');
		
		fetchInsightsData(1, insightsPageSize);
		updateHashParam("SegmentFilter", SegmentFilterVal);
		if(hashParamObj.page){
			updateHashParam('page', "");
		}
		initPagintation(firstPage);
	});

	$("#insightServiceArea").on('click', '.custom-select-list li', function () {
		insightServiceAreaVal = $(this).attr('data-list-value');
		
		fetchInsightsData(1, insightsPageSize);
		updateHashParam("ServiceAreaFilter", insightServiceAreaVal);
		if(hashParamObj.page){
			updateHashParam('page', "");
		}
		initPagintation(firstPage);
	});
	
	if(insightsPageSize) {
		if(hashParamObj && hashParamObj.page){
			fetchInsightsData(hashParamObj.page, insightsPageSize);
			initPagintation(hashParamObj.page);
		} else {
			fetchInsightsData(firstPage, insightsPageSize);
			initPagintation(firstPage);
		}
	}

	if(hashParamObj && hashParamObj.SegmentFilter) {
		$("#insightSegment .custom-select-list li").each(function(){
			if($(this).attr('data-list-value') === hashParamObj.SegmentFilter){
				$(this).addClass("active").siblings().removeClass("active");
				$("#insightSegment input").val($(this).text());
			}
		})
	}
	
	if(hashParamObj && hashParamObj.ServiceAreaFilter) {
		$("#insightServiceArea .custom-select-list li").each(function(){
			if($(this).attr('data-list-value') === hashParamObj.ServiceAreaFilter){
				$(this).addClass("active").siblings().removeClass("active");
				$("#insightServiceArea input").val($(this).text());
			}
		})
	}

	var insightFeedUrl = $("#insightFeedUrl").val();
	$("#insightFeedData").click(function(){
		var url = insightFeedUrl + "?PageNo=" + activePage + "&PageSize=" + insightsPageSize + "&SegmentFilter=" + SegmentFilterVal + "&ServiceAreaFilter=" + insightServiceAreaVal;
		window.open(url, '_blank');
	});

	// menu link jump to insight page section
	if (insightsPageSize) {
		var queryString = window.location.search.split("?")[1],
		    urlParams = new URLSearchParams(queryString);
		SegmentFilterVal = urlParams.get('ServiceAreaFilter');
		if (urlParams != "" && SegmentFilterVal != "") {
			$("#insights-archive .custom-select-list li").forEach(function (item) {
				var listVal = $(item).text();
				$(item).removeClass("active");
				if (SegmentFilterVal == $(item).attr('data-list-value')) {
					$(item).addClass("active");
					$("#insights-archive .custom-select-value").val(listVal);
				}
			});
			window.scrollTo({
				top: $('#insights-archive').offset().top - 150,
				behavior: "smooth"
			});
		}
		$(document).on('click', ".main-nav li", function (e) {
			var queryString = $(this).find("a").attr("href").split("?")[1],
				urlParams = new URLSearchParams(queryString);
			SegmentFilterVal = urlParams.get('ServiceAreaFilter');
			if (queryString && urlParams != "" && SegmentFilterVal != "") {
				$("#insights-archive .custom-select-list li").forEach(function (item) {
					var listVal = $(item).text();
					$(item).removeClass("active");
					if (SegmentFilterVal == $(item).attr('data-list-value')) {
						$(item).addClass("active");
						$("#insights-archive .custom-select-value").val(listVal);
					}
				});
				window.scrollTo({
					top: $('#insights-archive').offset().top - 150,
					behavior: "smooth"
				});
			}
		});
	}
});