var setupProductTable = function(tableSelector, showBtnSelector, hideBtnSelector, visibleRowCount = 5) {
  const $table = $(tableSelector);
  const $tbody = $table.find("tbody");
  const $rows = $tbody.find("tr");
  const $showBtn = $(showBtnSelector);
  const $hideBtn = $(hideBtnSelector);

  if ($rows.length <= visibleRowCount) {
    $showBtn.hide();
    $hideBtn.hide();
    return;
  }

  $rows.slice(visibleRowCount).addClass('hidden');
  $showBtn.show();
  $hideBtn.hide();

  $showBtn.off("click").click(function () {
    $rows.removeClass('hidden');
    $showBtn.hide();
    $hideBtn.show();
  });

  $hideBtn.off("click").click(function () {
    $rows.slice(visibleRowCount).addClass('hidden');
    $hideBtn.hide();
    $showBtn.show();
  });
}
// Setup tables on page load
setupProductTable(
  "#form-product-preferences > .page-preferences",
  ".showallproducts",
  ".hideallproducts",
  5
);

setupProductTable(
  "#form-product-preferences-none",
  ".showallproductsnone",
  ".hideallproductsnone",
  5
);


var isCreateMode = function (){
  var hiddenFrequencies = $("input[id$=Frequency]");
  var $radios;
  var createMode = false;
  var allProducts = true;
  var prevValue;
  for(var i = 0;i < hiddenFrequencies.length; i++) {
    if( hiddenFrequencies[i].value !== '' ){
      createMode = false;

      //If any value is differemnt from prevValue the allPtoducts in UI should be unchecked
      if( i > 0 &&
        hiddenFrequencies[i].value !== hiddenFrequencies[i-1].value ){
          allProducts = false;
      }
      $radios = $('input[name=ProductAlerts'+i+']');
      if($radios.length === 0 ){
        break;
      }
      $radios.filter('[value='+hiddenFrequencies[i].value+']').prop('checked', true);
    }else{
      createMode = true;
      break;
    }
  }
  if(!createMode && allProducts && hiddenFrequencies.length>0) {
    var $allProdsRadios = $('input[name=allProducts]');
    $allProdsRadios.filter('[value='+hiddenFrequencies[0].value+']').prop('checked', true);
  }
  return createMode;
};

var isCreateModeNone = function isCreateModeNone() {
        
  var hiddenFrequencies = $("input[id$=FrequencyN]");
  var $radios;
  var createMode = false;
  var allProducts = true;
  var prevValue;
  
  for (var i = 0; i < hiddenFrequencies.length; i++) {
     
      if (hiddenFrequencies[i].value !== '') {
          createMode = false;
        
          //If any value is differemnt from prevValue the allPtoducts in UI should be unchecked
          if (i > 0 && hiddenFrequencies[i].value !== hiddenFrequencies[i - 1].value) {
              allProducts = false;
          }
          $radios = $('input[name=ProductAlertsNone' + i + ']');
          if ($radios.length === 0) {
              
              break;
          }
          $radios.filter('[value=' + hiddenFrequencies[i].value + ']').prop('checked', true);
      } else {
          createMode = true;
         
          break;
      }
  }
  if (!createMode && allProducts) {
      var $allProdsRadios = $('input[name=allProductsN]');
      $allProdsRadios.filter('[value=' + hiddenFrequencies[0].value + ']').prop('checked', true);
  }
 
  return createMode;
};

$(function() {
  //If Create mode then set all proucts to weekly as defaiult value
  var $allProdsRadios = $('input[name=allProducts]');
  if(typeof $allProdsRadios !== "undefined" && $('input[name=allProducts]').length !== 0 && $allProdsRadios.is(':checked') === false && isCreateMode()) {
      $allProdsRadios.filter('[value=WEEKLY]').prop('checked', true);
      if ($("input[name='allProducts']:checked").val() !== "undefined") {
        setAllProductPreferences($("input[name='allProducts']:checked").val());
      }
  }
  //If Create mode then set all proucts(Notset) to weekly as defaiult value
  var $allProdsRadiosNone = $('input[name=allProductsN]');
  if (typeof $allProdsRadiosNone !== "undefined" && $('input[name=allProductsN]').length !== 0 && $allProdsRadiosNone.is(':checked') === false && isCreateModeNone()) {
      $allProdsRadiosNone.filter('[value=NONE]').prop('checked', true);
      setAllProductPreferencesNone($("input[name='allProductsN']:checked").val());
  }
});


var setAllProductPreferences = function(allProductsValue){
  var inputs = document.getElementById("form-product-preferences").elements;
  var $radios;
  for (var i = 0; i < inputs.length; i++) {
    $radios = $('input[name=ProductAlerts'+i+']');
    if($radios.length === 0 ){
      break;
    }
    $radios.filter('[value='+allProductsValue+']').prop('checked', true);
    //Set selectValue hidden field
    document.getElementById('ProductAlerts'+i+'Frequency').value = $("input[name='allProducts']:checked").val();
  }
};

var setAllProductPreferencesNone = function setAllProductPreferencesNone(allProductsValue) {
  var inputs = document.getElementById("form-product-preferences").elements;
  var $radios;
  for (var i = 0; i < inputs.length; i++) {
      $radios = $('input[name=ProductAlertsNone' + i + ']');
      if ($radios.length === 0) {
          break;
      }
      $radios.filter('[value=' + allProductsValue + ']').prop('checked', true);
      //Set selectValue hidden field
      document.getElementById('ProductAlertsNone' + i + 'FrequencyN').value = $("input[name='allProductsN']:checked").val();
  }
};

$("#form-product-preferences .product-banner-cta").attr("disabled", "disabled");
$('#form-product-preferences').change(function(){
  $("#form-product-preferences .product-banner-cta").removeAttr("disabled");
  var inputs = document.getElementById("form-product-preferences").elements;
  var productValue;
  for (var i = 0; i < inputs.length; i++) {
    if($('input[name=ProductAlerts'+i+']').length === 0 ){
      break;
    }
    productValue = $('input[name=ProductAlerts'+i+']:checked').val();
    //Set selectValue hidden field
    document.getElementById('ProductAlerts'+i+'Frequency').value = productValue;


  }

  for (var i = 0; i < inputs.length; i++) {
    if($('input[name=ProductAlerts'+i+']').length === 0 ){
      break;
    }
    productValue = $('input[name=ProductAlerts'+i+']:checked').val();

    // If AllProducts value different from any filter then check allProducts to false
    if( $("input[name='allProducts']:checked") &&
    $("input[name='allProducts']:checked").length > 0 &&
     $("input[name='allProducts']:checked").val() !== productValue ){
      var $allProdsRadios = $('input[name=allProducts]');
      $allProdsRadios.filter('[value="eee"]').prop('checked', false); // Setting a dummy value so  that selection is lost
      var isValueUnset = false;
            for (var i=0; i< $allProdsRadios.length; i++)  {
              if ($allProdsRadios[i].checked)  {
                $allProdsRadios[i].checked = false;
                isValueUnset = true;
                break;
              }
            }
       if(isValueUnset){
         break;
       }
    }

  }

});

$('#form-product-preferences-none').change(function () {
        
  var inputs = document.getElementById("form-product-preferences").elements;
  var productValue;
  for (var i = 0; i < inputs.length; i++) {
      if ($('input[name=ProductAlertsNone' + i + ']').length === 0) {
          break;
      }
      productValue = $('input[name=ProductAlertsNone' + i + ']:checked').val();
      //Set selectValue hidden field
      document.getElementById('ProductAlertsNone' + i + 'FrequencyN').value = productValue;
  }

  for (var i = 0; i < inputs.length; i++) {
      if ($('input[name=ProductAlertsNone' + i + ']').length === 0) {
          break;
      }
      productValue = $('input[name=ProductAlertsNone' + i + ']:checked').val();

      // If AllProducts value different from any filter then check allProducts to false
      if ($("input[name='allProductsN']:checked") && $("input[name='allProductsN']:checked").length > 0 && $("input[name='allProductsN']:checked").val() !== productValue) {
          var $allProdsRadios = $('input[name=allProductsN]');
          $allProdsRadios.filter('[value="eee"]').prop('checked', false); // Setting a dummy value so  that selection is lost
          var isValueUnset = false;
          for (var i = 0; i < $allProdsRadios.length; i++) {
              if ($allProdsRadios[i].checked) {
                  $allProdsRadios[i].checked = false;
                  isValueUnset = true;
                  break;
              }
          }
          if (isValueUnset) {
              break;
          }
      }
  }
});

//Set All products preference to all products on selection
$("input[name='allProducts']").change(function(){
  setAllProductPreferences($("input[name='allProducts']:checked").val());
});

//Set All products preference to all products on selection none
$("input[name='allProductsN']").change(function () {
  setAllProductPreferencesNone($("input[name='allProductsN']:checked").val());
});

// atleast 1 cheeckbox need to be checked while updating preferences
function updatePreferences(){
  var productPreferences = $("form#form-product-preferences");
  var isCheckboxAvailable = $(productPreferences).find("input[type=checkbox]");
  var areaInterest = $(productPreferences).find("input[type=checkbox]:checked");
  $(productPreferences).find(".alert-success.js-form-success").hide();
  if (isCheckboxAvailable.length > 0 && areaInterest.length < 1) {
    $(productPreferences).find(".alert-error.js-form-error").show();
    $(productPreferences).find("button[type=submit]").addClass("disabled");
    return false;
  }else{
    $(productPreferences).find(".alert-error.js-form-error").hide();
    $(productPreferences).find("button[type=submit]").removeClass("disabled");
  }
}
updatePreferences();
$("form#form-product-preferences").find("input[type=checkbox]").change(function(){
  updatePreferences();
});
