$(function () {	
	var blogListPagesize = $('#blog-page-size').val(),
        blogListDataTotalCount = $('#blog-result-count').val(),
		blogListFilterVal, hashParamObj, firstPage = 1, currentPage = firstPage,
		IsUserAuthenticated = $('#blog-user-is-authenticated').val();

    var parseHashParms = function(str) {
        str = str ? str.split("?")[1] : "";
        var pieces = str ? str.split("&") : "",
            data = {},
            i,
            parts;
        // process each query pair
        if(pieces && pieces.length > 0){
            for (i = 0; i < pieces.length; i++) {
                parts = pieces[i].split("=");
                if (parts.length < 2) {
                    parts.push("");
                }
                data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
            }
        }
        return data;
    };
	
	var updateHashParam = function updateHashParam(key, value) {
		var hashValue = window.location.search,
			paramObj = {};

		if (hashValue == "" && key != "page") {
			hashValue += key + "=" + value;
		} else {
			paramObj = parseHashParms(hashValue);
			if (value != "") {
				paramObj[key] = value;
				if(key == "page" && value < 2){
					delete paramObj[key];
				}
			} else {
				delete paramObj[key];
			}
			hashValue = Object.keys(paramObj).map(function (key) {
				return key + '=' + paramObj[key];
			}).join('&');
		}
		if (history.pushState) {
			if(hashValue != ""){
				var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?'+hashValue;
			}else{
				var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
			}
			window.history.pushState({path:newurl},'',newurl);
		}
	};
		
	var hashParam = window.location.search,
		hashParamObj = "";
	if (hashParam) {
		hashParamObj = parseHashParms(hashParam);
		if(hashParamObj.TaxonomyID){
			blogListFilterVal = hashParamObj.TaxonomyID;
			
			$("#blogListFilter .custom-select-list li").each(function(){
				if($(this).attr('data-list-value') === hashParamObj.TaxonomyID){
					$(this).addClass("active").siblings().removeClass("active");
					$("#blogListFilter input").val($(this).text());
				}
			})
		}		
	}
				
	var fetchBlogListData = function(pageNo, pageSize){		
		$("#blogListPaginationBackend").hide();
		$("#blogListDataFromBackend").hide();
		$.post("/api/blogsfilter/getblogitems", {
			"TaxonomyID": blogListFilterVal,
			"PageNo" : pageNo,
			"PageSize": pageSize
        }, function (data) {
            var blogListData = "";
			    data.respone.blogList.forEach(item => {
			blogListData += `
                <div class="tile-list">
                    <label class="action-flag pop-out__trigger ${IsUserAuthenticated ? "js-bookmark-article" : "js-pop-out-trigger"}"
                        data-pop-out-type="sign-in"
                        data-pop-out-align="right"
                        data-bookmark-id="${item.itemId}"
                        ${item.blogSalesforceId ? `data-salesforce-id="${item.blogSalesforceId}"` : `data-salesforce-id`}
                        ${item.isBookMarked ? "data-is-bookmarked=true" : ""}
                        data-info='{
                                        "event_name": "${item.isBookMarked ? "unbookmark" : "bookmark"}",
                                        "toolbar_tool": "bookmark",
                                        "bookmark_title": "${item.title}"}'>

                        <svg class="action-flag__icon action-flag__icon--bookmark article-bookmark article-bookmark__bookmarked ${item.isBookMarked ? "is-visible" : null}">
                            <use xlink:href="/dist/img/svg-sprite.svg#bookmark_filled"></use>
                        </svg>

                        <svg class="action-flag__icon action-flag__icon--bookmark article-bookmark ${item.isBookMarked ? null : "is-visible"}">
                            <use xlink:href="/dist/img/svg-sprite.svg#bookmark_border"></use>
                        </svg>
                    </label>
                    
                    <h3 class="tile-list-title"><a href="${item.url}">${item.title}</a></h3>
                    <div class="tile-list-metas">
                        <span>${item.publicationDate}</span>
                        <span>${
							item.blogAuthorNames != null && item.blogAuthorNames.length > 0 ? 
								item.blogAuthorNames.map(name => {
									return (' <a href="/authors/' + name + '">' + name + '</a>')
								})
								: ""
						}</span>
                    </div>
                    <p>${
						item.summary != null && item.summary != "" ? item.summary : ""
					}</p>
                    <a href="${item.url}" target="_blank" class="tile-list-link readmore">Read More</a>
                </div>`;
			});

			$("#blogListDataFromFrontend").html(blogListData);
            hashParam = window.location.search;
			if (hashParam) {
				hashParamObj = parseHashParms(hashParam);
			}
			$("#blogListPagination").pagination({
				dataTotalLength: data.respone.count,
				currentPageItem: pageNo,
				dataPerPage: pageSize
			});
			// initiate signin popup and bookmark function
			window.indexPopOuts();
			window.indexBookmarks();
        });
    }
	
	if(blogListPagesize) {
		if(hashParamObj && hashParamObj.page){
			fetchBlogListData(hashParamObj.page, blogListPagesize);
			$('link[rel="canonical"]').attr('href', window.location);
		} else {
			fetchBlogListData(firstPage, blogListPagesize);
		}
	}
	
	$("#blogListPagination").on('click', "li", function (e) {
		e.preventDefault();
		var pageIndex = $(this).find("a").attr("data-index");
		currentPage = pageIndex;
		updateHashParam("page", pageIndex);
		fetchBlogListData(pageIndex, blogListPagesize);
		$('link[rel="canonical"]').attr('href', window.location);
	});
	
	$("#blogListFilter").on('click', '.custom-select-list li', function () {
		blogListFilterVal = $(this).attr('data-list-value');	
			
		fetchBlogListData(firstPage, blogListPagesize);
		updateHashParam("TaxonomyID", blogListFilterVal);
		if(hashParamObj.page){
			updateHashParam('page', "");
		}
		$("#blogListPagination").pagination({
			currentPageItem: firstPage,
		});
	});
	
	var blogFeedUrl = $("#blogFeedUrl").val();
	$("#blogFeedData").click(function(){
		var url = blogFeedUrl + "?PageNo=" + currentPage + "&PageSize=" + blogListPagesize + "&TaxonomyID=" + blogListFilterVal;
		window.open(url, '_blank');
	});

    $.fn.pagination = function (options) {		
		var _this = this,
			defaults = {
				dataPerPage: 10,
				populatePageCount: 5,
				startPageFrom: 1,
			},
			settings = $.extend({}, defaults, options),
			firstPageIndex = 1,
			initPageIndex = settings.startPageFrom,
			lastPageIndex = settings.populatePageCount,
			pageIncrement,
			activePageIndex = 1,
			totalPageCount = "",
			currentPageItem = settings.currentPageItem;
			
			
		if(this.length > 1){
			this.each(function(){ $(this).pagination(options) });
			return this;
		}
	
		this.populateData = function(){
			totalPageCount = Math.ceil(settings.dataTotalLength / settings.dataPerPage);
			var paginationItem = "";
				
			if(initPageIndex != firstPageIndex){
				paginationItem += `<li>
									<a href="#?page=${firstPageIndex}" data-index="${firstPageIndex}" title="Page ${firstPageIndex}">First</a>
								   </li>
								   <li>...</li>`;
			}
			
			for (var i = initPageIndex; i <= totalPageCount; i++) {
				if(i <= lastPageIndex){
					if(activePageIndex && activePageIndex == i){
						paginationItem += `<li class="active">
											<a href="#?page=${i}" data-index="${i}" title="Page ${i}">${i}</a>
										  </li>`;
					}else{					
						paginationItem += `<li>
											<a href="#?page=${i}" data-index="${i}" title="Page ${i}">${i}</a>
										  </li>`;
					}
				}
			}
			
			if(totalPageCount > lastPageIndex){
				paginationItem += `<li>...</li>`
				
				if(totalPageCount == activePageIndex){
					paginationItem += `<li class"active">
										<a href="#?page=${totalPageCount}" data-index="${totalPageCount}" title="Page ${totalPageCount}">Last</a>
									  </li>`;
				}else {
					paginationItem += `<li>
										<a href="#?page=${totalPageCount}" data-index="${totalPageCount}" title="Page ${totalPageCount}">Last</a>
									  </li>`;
				}
			}
			
			if (totalPageCount <= firstPageIndex) {
				paginationItem = "";
			}			
			this.html(paginationItem);	
		}
		
		this.initialize = function(){
			_this.populateData();
				
			if(currentPageItem){
				activePageIndex = currentPageItem;
			}

			if (currentPageItem && currentPageItem > 2 && currentPageItem <= totalPageCount - 2) {
				initPageIndex = Number(currentPageItem) - 2;
				lastPageIndex = Number(currentPageItem) + 2;
			}
			if (currentPageItem && currentPageItem <= 2) {
				initPageIndex = settings.startPageFrom,
				lastPageIndex = settings.populatePageCount
			}
			if(totalPageCount > 5){
				if (currentPageItem && currentPageItem >= totalPageCount - 2) {
					initPageIndex = totalPageCount - 4;
					lastPageIndex = totalPageCount;
				}
			}
			_this.populateData();
		};
		this.initialize();		
			
		$(_this).on('click', "li", function(e){
			e.preventDefault();
			//e.stopPropagation();
			currentPageItem = $(this).find("a").attr("data-index");
			_this.initialize();
		});
	};
});