$(function () {
  var inputs = $('input[type="radio"]'),
    productValue,
    rowID;

  $('#form-search-preferences input[type="radio"]').change(function (event) {
    $("#btnsubmitnone").removeAttr("disabled");
    for (var i = 0; i < inputs.length; i++) {
      if ($("input[name=SavedSearchInput" + i + "]").length === 0) {
        break;
      }
      productValue = $(event.currentTarget).val();
      rowID = $(event.currentTarget).closest("tr").attr("id").split("row")[1];

      //Set selectValue hidden field
      $("#SavedSearchInput" + rowID + "Frequency").val(productValue);
      return;
    }
  });

  $('#form-search-preferences-none input[type="radio"]').change(function (
    event
  ) {
    $("#btnsubmitnone").removeAttr("disabled");
    for (var i = 0; i < inputs.length; i++) {
      if ($("input[name=SavedSearchInputN" + i + "]").length === 0) {
        break;
      }
      productValue = $(event.currentTarget).val();
      rowID = $(event.currentTarget).closest("tr").attr("id").split("row")[1];

      //Set selectValue for none hidden field
      $("#SavedSearchInputNone" + rowID + "FrequencyN").val(productValue);
      return;
    }
  });
});

function getFormURLEncoded() {
  var urlEncodedData = new URLSearchParams();
  // Add data from the first table
  var firstTableRows = document.querySelectorAll(
    "#form-search-preferences tbody tr"
  );
  var counter = 0;
  // Process first table rows (with active frequency)
  firstTableRows.forEach(function (row) {
    // Skip rows that don't have proper inputs (like "No records found" rows)
    if (!row.querySelector('input[type="hidden"]')) {
      return;
    }
    // Get all required fields from this row
    var title = row.querySelector('input[id$="Title"]')
      ? row.querySelector('input[id$="Title"]').value
      : "";
    var url = row.querySelector('input[id$="Url"]')
      ? row.querySelector('input[id$="Url"]').value
      : "";
    var alertEnabled = row.querySelector('input[id$="AlertEnabled"]')
      ? row.querySelector('input[id$="AlertEnabled"]').value
      : "false";
    var id = row.querySelector('input[id$="Id"]')
      ? row.querySelector('input[id$="Id"]').value
      : "";
    var frequency = row.querySelector('input[id$="Frequency"]')
      ? row.querySelector('input[id$="Frequency"]').value
      : "";

    // Add all fields to the form data
    urlEncodedData.append("SavedSearchInputs[" + counter + "].Title", title);
    urlEncodedData.append("SavedSearchInputs[" + counter + "].Url", url);
    urlEncodedData.append(
      "SavedSearchInputs[" + counter + "].AlertEnabled",
      alertEnabled
    );
    urlEncodedData.append("SavedSearchInputs[" + counter + "].Id", id);
    urlEncodedData.append(
      "SavedSearchInputs[" + counter + "].Frequency",
      frequency
    );

    counter++;
  });

  // Process second table rows (with NONE frequency)
  var secondTableRows = document.querySelectorAll(
    "#form-search-preferences-none tbody tr"
  );

  secondTableRows.forEach(function (row) {
    // Skip rows that don't have proper inputs
    if (!row.querySelector('input[type="hidden"]')) {
      return;
    }

    // Get all required fields from this row
    var title = row.querySelector('input[id$="Title"]')
      ? row.querySelector('input[id$="Title"]').value
      : "";
    var url = row.querySelector('input[id$="Url"]')
      ? row.querySelector('input[id$="Url"]').value
      : "";
    var alertEnabled = row.querySelector('input[id$="AlertEnabled"]')
      ? row.querySelector('input[id$="AlertEnabled"]').value
      : "false";
    var id = row.querySelector('input[id$="Id"]')
      ? row.querySelector('input[id$="Id"]').value
      : "";

    // Get selected radio value for frequency (or use the hidden field)
    var checkedRadio = row.querySelector('input[type="radio"]:checked');
    var frequency = checkedRadio ? checkedRadio.value : "NONE";

    // Add all fields to the form data with continuous indices
    urlEncodedData.append("SavedSearchInputs[" + counter + "].Title", title);
    urlEncodedData.append("SavedSearchInputs[" + counter + "].Url", url);
    urlEncodedData.append(
      "SavedSearchInputs[" + counter + "].AlertEnabled",
      alertEnabled
    );
    urlEncodedData.append("SavedSearchInputs[" + counter + "].Id", id);
    urlEncodedData.append(
      "SavedSearchInputs[" + counter + "].Frequency",
      frequency
    );

    counter++;
  });

  return urlEncodedData.toString();
}

document.addEventListener("DOMContentLoaded", function () {
  var spinner = document.querySelector(".load-spinner");
  function submitForm() {
    spinner.classList.add("show");
    return fetch("/api/SavedSearches/SaveAlertPreferences", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded", // Keep as form-urlencoded
      },
      body: getFormURLEncoded(), // Send form-urlencoded data
    });
  }
  let button = document.getElementById("btnsubmitnone");
  if (button != null) {
    button.addEventListener("click", function (event) {
      event.preventDefault();
      submitForm()
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          window.location.reload();
        })
        ["catch"](function (error) {
          console.error("Error:", error);
        });
    });
  }

  var successForm = $("#empty-form");
  if (successForm.length) {
    successForm.find("button[type=submit]").click(function () {
      setTimeout(function () {
        if ($("#form-search-preferences tbody tr input").length == 0) {
          window.location.reload();
        }
      }, 3000);
    });
  }

  var successFormNone = $("#empty-form-none");
  if (successFormNone.length) {
    successFormNone.find("button[type=submit]").click(function () {
      setTimeout(function () {
        if ($("#form-search-preferences-none tbody tr input").length == 0) {
          window.location.reload();
        }
      }, 3000);
    });
  }
  // sticky button functionality for "save search alert" and "email-preferences" page
  let footer = document.querySelector("footer");
  let productBannerCta = document.querySelector(".product-banner-cta");

  let createObserver = function (stickyEle) {  
    let observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            stickyEle.classList.add("fixed");
          } else {
            stickyEle.classList.remove("fixed"); 
          }
        });
      },
      { root: null, threshold: 0.1, rootMargin: "0px 0px -100px 0px" } 
    );
    observer.observe(stickyEle);
    return observer;
  };
  
  if (button) {
    var stickySaveSearchObserver = createObserver(button);
  }
  
  if (productBannerCta) {
    var stickyPreferenceObserver = createObserver(productBannerCta);
  }
});
