$(function(){
    var collections = "",
        collectionList = {},
        collectionLength = "",
        currItem = 0,
        seeMoreBtn = $(".see-more-btn"),
        collectionTile = "",
        pageIndex = 1,
        collectionProductCode = $('[name="collection-listing-product-code"]').val();

    var loadMoreCollection = function(pageNo, productCode){
        $(seeMoreBtn).hide();
        $(".collections-set").append('<img class="loading" src="/dist/img/loader.svg">');
        $.ajax({
            url: '/api/relatedcollection/getcollectionitems',
            data: { 'PageNo': pageNo, "ProductCode": productCode },
            type: 'POST',
            success: function success(data) {
                collections = data.response;
                collectionList = collections.collectionList;
                collectionLength = collectionList.length;
                $(seeMoreBtn).html(collections.seeMoreLabel);
                
                if(collectionLength > 0) {
                    for (let currItem = 0; currItem < collectionLength; currItem++) {
                        collectionTile += `<div class="inf-col-fluid ">
                                            <div class="qc-class">
                                                <div class="qc-title">${collectionList[currItem].title}
                                                    <div class="action-flag pop-out__trigger ${collectionList[currItem].isUserAuthenticated ? "js-bookmark-article" : "js-pop-out-trigger"}" data-pop-out-type="sign-in" data-pop-out-align="right" 
                                                        data-bookmark-id="${collectionList[currItem].id}" data-salesforce-id="" data-info="{
                                                            "event_name": "bookmark",
                                                            "toolbar_tool": "bookmark",
                                                            "bookmark_title": "${collectionList[currItem].title}"
                                                        }" 
                                                        data-is-bookmarked="${collectionList[currItem].isArticleBookmarked}">

                                                        <svg class="action-flag__icon action-flag__icon--bookmark article-bookmark article-bookmark__bookmarked">
                                                            <use xlink:href="/dist/img/svg-sprite.svg#bookmark_filled"></use>
                                                        </svg>

                                                        <svg class="action-flag__icon action-flag__icon--bookmark article-bookmark is-visible">
                                                            <use xlink:href="/dist/img/svg-sprite.svg#bookmark_border"></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <span class="qc-link">${collectionList[currItem].periodicity}</span>
                                                <p class="date">${collections.dateLabel} <span>${collectionList[currItem].formattedDate}</span></p>
                                                <a href="${collectionList[currItem].link}" class="btn secondary omd-icon-arrow">Go To Collection</a>
                                            </div>
                                        </div>`;
                    }
                }
                $(".collections-set .loading").remove();
                $(".collections-set .omd-mkt-col-3").append(collectionTile);
                $(seeMoreBtn).show();
                collectionTile = "";
                pageIndex++;
                
                if(collections.showSeeMore != true){
                    $(seeMoreBtn).hide();
                }
            },
            error: function error(err) {
                console.log('Data is not there: ' + JSON.stringify(err));
            }
        });
    }
    $(seeMoreBtn).click(function(){
        loadMoreCollection(pageIndex, collectionProductCode);
    })
});